export function TooltipDiamond() {
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute lg:w-6 lg:h-6 h-8 w-8 text-spindle-200 shadow-spindle-200  transform rotate-45 -translate-y-1/2 fill-current -right-3 top-1/2"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z"></path>
      </svg>
    )
}