export function Logo() {
  return (
    <span
      className="-m-1.5 p-1.5 text-bermuda-300 font-logoPart1 text-4xl select-none"
    >
      Digit<span className="font-display font-bold">al</span> Dom
      <span className="font-display font-bold">ain</span>
    </span>
  );
}
